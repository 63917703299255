// IndustryBox.js

import React from 'react';
import './IndustryBox.css';

const IndustryBox = ({ title, icon }) => {
    return (
        <div className="industry-box">
            <img src={icon} alt={title} className="icon" />
            <div className="title">{title}</div>
        </div>
    );
};

export default IndustryBox;
