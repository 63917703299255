import React from 'react';
import './App.css';
// Import components
import IndustryBox from './IndustryBox';
import ContactUs from './ContactUs';



// Import icons
import educationIcon from './assets/icons/icon2.png';
import fmcgIcon from './assets/icons/icon4.png';
import manufacturingIcon from './assets/icons/icon5.png';
import gamesDevelopmentIcon from './assets/icons/icon6.png';
import blockchainIcon from './assets/icons/icon7.png';
import eCommerceIcon from './assets/icons/icon9.png';
import socialMediaIcon from './assets/icons/icon10.png';
import animatedVideosIcon from './assets/icons/icon12.png';
import chatBotsIcon from './assets/icons/icon13.png';
import transportationRetailIcon from './assets/icons/icon14.png';


import shipsmartImage from './Ship-Smart.jpg'; // Update with the correct path
// import docmanagementImage from './Document-Mangement.jpg'; // Update with the correct path

const App = () => {
   // Define your industries and corresponding icons
   const industries = [
    { title: 'Education', icon: educationIcon },
    { title: 'FMCG', icon: fmcgIcon },
    { title: 'Manufacturing', icon: manufacturingIcon },
    { title: 'Games Development', icon: gamesDevelopmentIcon },
    { title: 'Blockchain', icon: blockchainIcon },
    { title: 'E-Commerce', icon: eCommerceIcon },
    { title: 'Social Media', icon: socialMediaIcon },
    { title: '3D Animated Videos', icon: animatedVideosIcon },
    { title: 'Chat Bots', icon: chatBotsIcon },
    { title: 'Transportation & Retail', icon: transportationRetailIcon },
];

  const viewPDF = () => {
    // Implementation to view the PDF
    const pdfUrl = '/pdfs/ShipSmart.pdf'; 
    window.open(pdfUrl, '_blank');
  };
      return (
        <div>
            <nav className="navbar">
                <div className="navbar-brand">
                    <img src="/logo.png" alt="Pearl Dynamics Logo" className="logo" />
                    <div className="brand-text">
                        <span className="brand-name">Pearl Dynamics</span>
                        <span className="slogan">
                            <span className="italic">E</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">n</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">a</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">b</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">l</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">i</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">n</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">g</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">D</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">i</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">g</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">i</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">t</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">a</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">l</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">D</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">i</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">s</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">r</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">u</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">p</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">t</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">i</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">o</span>
                            <span className="italic">&nbsp;</span>
                            <span className="italic">n</span>
                        </span>
                    </div>
                </div>
            </nav>
            <div id="home" className="section">
                <img src="/placeholder1.jpg" alt="Welcome to Pearl Dynamics" className="section-image" />
                <div className="section-text">
                    <h1 className="heading">Welcome to Pearl Dynamics</h1>
                    <p>Your partner in innovative software solutions</p>
                </div>
            </div>
            <div id="how-we-serve" className="text-section">
                <h1 className="heading">How We Serve</h1>
                <p className="subheading">We provide personalized, end-to-end software services to drive your business success</p>
                <div className="services-grid">
                    <div className="service-card">
                        <h2>SAAS Development</h2>
                        <p>We specialize in creating scalable SaaS solutions using cutting-edge technologies to deliver secure, user-friendly, and efficient software that fosters growth and innovation. From concept to deployment and ongoing support, we ensure a seamless development process, enabling software that evolves with your business.</p>
                    </div>
                    <div className="service-card">
                        <h2>User Experience</h2>
                        <p>We prioritize user-centric design to create intuitive and engaging experiences. Our expert team crafts seamless interfaces that enhance usability and satisfaction, ensuring your software is both functional and delightful to use.</p>
                    </div>
                    <div className="service-card">
                        <h2>Wearable & Cross Platform</h2>
                        <p>We excels in creating innovative wearable and cross-platform solutions. We ensure your applications provide a seamless experience across various devices, enhancing accessibility and user engagement while leveraging the latest technologies.</p>
                    </div>
                    <div className="service-card">
                        <h2>Big Data / ETL / BI</h2>
                        <p>We offers comprehensive Big Data, ETL, and BI solutions to help you harness the power of your data. Our services enable efficient data extraction, transformation, and loading, coupled with advanced business intelligence tools to drive informed decision-making and strategic growth.</p>
                    </div>
                    <div className="service-card">
                        <h2>Containerization / Kubernetes / DevOps</h2>
                        <p>We provides cutting-edge Containerization and Kubernetes solutions integrated with DevOps practices. We ensure efficient deployment, scalability, and management of your applications, optimizing your development pipeline for faster, more reliable delivery.</p>
                    </div>
                    <div className="service-card">
                        <h2>Generative AI</h2>
                        <p>We leverage Generative AI to innovate and automate complex processes. Our advanced AI solutions, NLP and deep learning create new possibilities for your business, from content generation to predictive analytics, driving efficiency and enhancing creativity.</p>
                    </div>
                </div>
            </div>
            <div id="technology-stack" className="text-section tech-stack">
                <h1 className="heading1">Technology Stack</h1>
                <p className="subheading1">Our technology stack leverages the latest advancements to ensure robust and scalable solutions for your business challenges</p>
                <div className="tech-icons">
                <div className="tech-icon-box">
                    <img src="/react native.png" alt="React / React Native" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/GCP.png" alt="Google Cloud Platform" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/android.png" alt="Android" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/ios.png" alt="ios" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/node.png" alt="Node" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/dot net core.png" alt=".net core" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/aws.png" alt="AWS" className="tech-icon" />
                    </div>
                    <div className="tech-icon-box">
                    <img src="/azure.png" alt="Azure" className="tech-icon" />
                    </div>
                </div>
            </div>
            <div id="about-us" className="text-section">
                <h1 className="heading">About Us</h1>
                <p className="subheading">At Pearl Dynamics, we excel in Software Development; creating customized solutions for your business.<br/>Our Cloud Services offer secure and scalable environments.</p>
                <p className="subheading">With our AI Integration, we bring advanced intelligence to your operations. <br />We also specialize in Cross Platform App Development, ensuring your apps work flawlessly on any device.</p>
                <p className="subheading">Join us to bring your ideas to life with innovation and expertise.</p>
                {/* <p className="subheading">At Pearl Dynamics, we excel in Software Development; creating customized solutions for your business.</p>
                <p className="subheading">Our Cloud Services offer secure and scalable environments.<br />With our AI Integration, we bring advanced intelligence to your operations.<br />We also specialize in Cross Platform App Development, ensuring your apps work flawlessly on any device.</p>
                <p className="subheading">Join us to bring your ideas to life with innovation and expertise.</p> */}
            </div>
            <section id="our-industries">
                <h2 className="ourindustries">Our Industries</h2>
                <div className="industry-boxes">
                    {industries.map((industry, index) => (
                        <IndustryBox key={index} title={industry.title} icon={industry.icon} />
                    ))}
                </div>
            </section>
            <div id="products" className="text-section">
                <h1 className="heading">Our Products</h1>
                <p className="subheading">We offer comprehensive suite of innovative software solutions tailored to your needs</p>
            </div>

            <main>
        <section className="products">
          <div className="product-section">
            <div className="product-card">
              <img src={shipsmartImage} alt="L'oreal SYNC" />
              <div className="product-info">
                <h3>Ship Smart</h3>
                <p>This is an AI based end-to-end digital solution for clearing & forwarding companies, revolutionizing import / export and logistics management.</p>
                <button className='view-pdf-button'onClick={viewPDF}>View PDF</button>
              </div>
            </div>
            {/* <div className="product-card">
              <img src={docmanagementImage} alt="S&D IAC" />
              <div className="product-info">
                <h3>Document Mangement</h3>
                <p>Our Document Management system is designed to streamline your document storage, organization, and retrieval processes.</p>
              </div>
            </div> */}
          </div>
        </section>
      </main>

            <div id="career" className="text-section">
                <h1 className="heading">Careers</h1>
                <p className="subheading">Join our team and grow your career with us</p>
                <p className="subheading">Please email your CV to hr@pearlsdynamics.com</p>
            </div>
            <section className="career">
              <div className="job-listing">
                <div className="job">
                   <h3>BackEnd Developer (.net core)</h3>
                   <p>
                   Join our team at Pearl Dynamics as a Backend Developer specializing in .NET Core. You will play a pivotal role in designing, developing, and maintaining scalable backend solutions that power our innovative software products. 
                   </p>
                   <button className="apply-now" onClick={() => window.open("mailto:hr@pearlsdynamics.com?subject=Applying for BackEnd Developer (.net core)")}>Apply Now</button>
                </div>
                <div className="job">
                   <h3>React Developer</h3>
                   <p>
                   Embark on an exciting journey with Pearl Dynamics as a React Developer. Join our team to craft innovative front-end solutions using React.js, collaborating closely with designers and backend developers to deliver seamless user experiences. 
                   </p>
                   <button className="apply-now" onClick={() => window.open("mailto:hr@pearlsdynamics.com?subject=Applying for React Developer")}>Apply Now</button>
                </div>
                <div className="job">
                   <h3>Cross Platform Developer</h3>
                   <p>
                   Join Pearl Dynamics as a Cross-Platform Developer and be part of a team driving innovation across multiple platforms. Utilize your expertise to develop versatile applications that deliver seamless experiences across different devices and operating systems. 
                   </p>
                   <button className="apply-now" onClick={() => window.open("mailto:hr@pearlsdynamics.com?subject=Applying for Cross Platform Developer")}>Apply Now</button>
                </div>
                <div className="job">
                  <h3>QA Engineer</h3>
                  <p>
                  Become a pivotal part of Pearl Dynamics as a QA Engineer. Join our team to ensure the highest quality standards for our software products through rigorous testing and quality assurance processes.
                  </p>
                  <button className="apply-now" onClick={() => window.open("mailto:hr@pearlsdynamics.com?subject=Applying for QA Engineer")}>Apply Now</button>
                </div>
              </div>
            </section>

            <div id="contact-us" className="text-section">
            <h1 className="heading">Contact Us</h1>
            <p className="subheading">Please get in touch.<br />For Solutions, Support and Guidance.</p>
            </div>
            <div id="contact-us" className="section">
            {/* <form className="contact-form">
              <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <input type="text" id="fullName" name="fullName" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company</label>
                <input type="text" id="company" name="company" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" name="phone" />
              </div>
              <div className="form-group">
                <label htmlFor="source">How did you hear about us?</label>
                <input type="text" id="source" name="source" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Your Message</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button type="submit" className="submit-btn">Submit</button>
            </form> */}
            <ContactUs /> 
            <div className="contact-details">
              <div className="contact-info">
                <div className="info-item">
                  <img src="/icon5.svg" alt="icon" />
                  <p>Pearl Dynamics</p>
                </div>
                <div className="info-item">
                  <img src="/icon6.svg" alt="icon" />
                  <p>+92 3322087334</p>
                </div>
                <div className="info-item">
                  <img src="/icon7.svg" alt="icon" />
                  <p>A-41, Ground Floor, Railway Society, Block 10-A, Gulshan-e-Iqbal, Karachi</p>
                </div>
                <div className="info-item">
                  <img src="/icon8.svg" alt="icon" />
                  <p>info@pearlsdynamics.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default App;

